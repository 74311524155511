#content {
    padding: 85px;
}

#info-overlay {
    position: fixed;
    top: 0;
    left: 0;

    font-size: 1.5rem;

    background: white;
    padding: 15px;
    box-shadow: 2px 2px 0 2px #000;
    width: 400px;
}

#pathId {
    font-family: monospace;
}

small {
    font-size: 0.8rem;
}

#map {
    width: 4000px;
}
